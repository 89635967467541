<template>
  <div>
    <div
      class="order-history-wrap emb-card px-4"
      v-if="histories_data.length != 0"
    >
      <v-timeline align-top dense>
        <v-timeline-item
          color="blue"
          small
          v-for="(history_item, key) in histories_data"
          :key="key"
        >
          <v-row class="pt-1">
            <v-col cols="3">
              <strong
                >{{ history_item.creation_date.toDate().toLocaleDateString() }}
                <br />
                {{ history_item.creation_date.toDate().toLocaleTimeString() }}
              </strong>
            </v-col>
            <v-col>
              <strong>{{ get_title(history_item) }}</strong>
              <div class="text-caption" v-html="get_desc(history_item)"></div>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </div>
    <div class="order-history-wrap emb-card pa-4" v-else>
      {{ $t("message.myTicketsPage.noOrder") }}
    </div>
  </div>
</template>

<script>
import {
  getFirestore,
  doc,
  collection,
  orderBy,
  getDocs,
  query,
} from "firebase/firestore";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getUser", "getUserInfo"]),
  },
  props: ["exclude"],
  data() {
    return {
      customer_id: "",
      histories_fetched: false,
      histories_data: [],
    };
  },
  watch: {
    "$store.state.user": function () {
      if (this.getUser != undefined && !this.histories_fetched) {
        this.fetch_user_history();
      }
    },
  },
  mounted() {
    this.customer_id = this.$route.params.id;
    if (this.getUser != undefined && !this.histories_fetched) {
      this.fetch_user_history();
    }
  },
  methods: {
    fetch_user_history() {
      const docRef = doc(getFirestore(), "users", this.customer_id);
      const historyRef = collection(docRef, "history");
      const orderedChildQuery = query(
        historyRef,
        orderBy("creation_date", "desc")
      );

      getDocs(orderedChildQuery).then((querySnapshot) => {
        this.histories_fetched = true;
        var documents = [];
        querySnapshot.forEach((doc_hist) => {
          documents.push(doc_hist.data());
        });
        this.histories_data = documents;
      });
    },
    get_title(item) {
      if (item.type == "UPDATE_CREDIT") {
        return "Credit updated manualy";
      } else if (item.type == "UPDATE_CREDIT_FROM_WINNING") {
        return "Credit updated from winning";
      } else {
        return "Unknown type: " + item.type;
      }
    },
    get_desc(item) {
      if (item.type == "UPDATE_CREDIT") {
        var desc = `Update credit from ${item.content.old_credit} USD to ${item.content.credit} USD by user <a href='/admin-panel/customer-profile/${item.content.updated_user_id}'>${item.content.updated_user_id}</a>`;
        if (item.content.reason != undefined && item.content.reason != "") {
          desc += `<br>Reason: ${item.content.reason}`;
        }
        return desc;
      } else if (item.type == "UPDATE_CREDIT_FROM_WINNING") {
        return `Update credit from ${item.content.old_credit} USD to ${item.content.credit} USD for ticket ${item.content.ticket_id}`;
      } else {
        return "";
      }
    },
  },
};
</script>

